export const environment = {
  production: true,
  socketUrl: 'https://kingpin.pro:8443',
  apiUrl: 'https://kingpin.pro/api',
  assetUrl: 'https://kingpin.pro',
  baseUrl: 'https://kingpin.pro',
  googleClient:
    '259430508825-iaecq6am6sbcanpouq5l6tk8u22lflc5.apps.googleusercontent.com',
  stpe_key: 'pk_test_CWmTHI8YCQWqT3KOyUbbToCD',
};
